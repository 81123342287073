import { DeleteDialogService } from 'services/delete-dialog-service';
import { ErrorService } from 'services/error-service';
import { Models } from 'models/core';
import { autoinject, containerless } from 'aurelia-framework';
import { bindable } from 'aurelia-framework';
import { SiteService } from 'services/site-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { ToastService } from 'services/toast-service';
import { Router } from 'aurelia-router';
import {
  ValidationController,
  ValidationRules,
  Validator,
} from 'aurelia-validation';
import { I18N } from 'aurelia-i18n';
import { NUMBER_WITH_OPTIONAL_MAX_TWO_DECIMALS } from 'lib/regexes';

@containerless
@autoinject
export class SiteFormInlineEdit {
  @bindable site;
  @bindable departments: Array<Models.Department>;
  @bindable customerIndustryId: number;
  private aquacomOptions: any[];
  private editedSite: Models.Site = new Models.Site();

  constructor(
    private siteService: SiteService,
    private eventAggregator: EventAggregator,
    private router: Router,
    private toastService: ToastService,
    private errorService: ErrorService,
    private deleteDialogService: DeleteDialogService,
    private validationController: ValidationController,
    private validator: Validator,
    private i18n: I18N
  ) {
    ValidationRules.ensure('Hs')
      .matches(NUMBER_WITH_OPTIONAL_MAX_TWO_DECIMALS)
      .withMessage(this.i18n.tr('validation.numberMaxTwoDecimals'))
      .ensure('Vc')
      .matches(NUMBER_WITH_OPTIONAL_MAX_TWO_DECIMALS)
      .withMessage(this.i18n.tr('validation.numberMaxTwoDecimals'))
      .on(this.editedSite);
  }

  attached() {
    this.editedSite.CustomerId = this.site.CustomerId;
    this.editedSite.DepartmentId = this.site.DepartmentId;
    this.editedSite.Id = this.site.Id;
    this.editedSite.Name = this.site.Name;
    this.editedSite.AquacomId = this.site.AquacomId;
    this.editedSite.Hs = this.site.Hs;
    this.editedSite.Vc = this.site.Vc;
  }

  async updateSite() {
    const validation = await this.validationController.validate();

    if (validation.valid) {
      try {
        await this.siteService.put(this.editedSite, this.site.Id);
        this.eventAggregator.publish('siteListReset', 1);
        this.eventAggregator.publish('siteFormEditClose', 1);
        this.toastService.showSuccess('site.updated');
      } catch (error) {
        this.errorService.handleError(error);
      }
    }
    return;
  }

  deleteSite() {
    this.deleteDialogService.confirmBeforeDelete(() => {
      this.siteService
        .delete(this.site.Id)
        .then((res) => {
          this.eventAggregator.publish('siteFormEditClose', 1);
          this.eventAggregator.publish('siteListReset', 1);
          this.toastService.showSuccess('site.deleted');
        })
        .catch((err) => this.errorService.handleError(err));
    });
  }

  cancelEdit() {
    this.eventAggregator.publish('siteFormEditClose', 1);
    this.eventAggregator.publish('siteListReset', 1);
  }
}
