import { I18N } from 'aurelia-i18n';
import { Prompt } from './../../elements/prompt';
import { DialogService } from 'aurelia-dialog';
import { ErrorService } from 'services/error-service';
import { Models } from 'models/core';
import { autoinject, containerless } from 'aurelia-framework';
import { bindable } from 'aurelia-framework';
import { SiteService } from 'services/site-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { ToastService } from 'services/toast-service';
import { Router } from 'aurelia-router';
import {
  ValidationController,
  ValidationRules,
  Validator,
} from 'aurelia-validation';
import { NUMBER_WITH_OPTIONAL_MAX_TWO_DECIMALS } from 'lib/regexes';

@containerless
@autoinject
export class SiteFormInlineNew {
  @bindable customerId: number;
  @bindable customerIndustryId: number;
  @bindable departmentId: number;
  private site: Models.Site = new Models.Site();

  private aquacomOptions: any[];
  private aquacomModuleId: number;

  constructor(
    private siteService: SiteService,
    private eventAggregator: EventAggregator,
    private router: Router,
    private toastService: ToastService,
    private dialogService: DialogService,
    private validationController: ValidationController,
    private validator: Validator,
    private i18n: I18N,
    private errorService: ErrorService
  ) {
    ValidationRules.ensure('Hs')
      .matches(NUMBER_WITH_OPTIONAL_MAX_TWO_DECIMALS)
      .withMessage(this.i18n.tr('validation.numberMaxTwoDecimals'))
      .ensure('Vc')
      .matches(NUMBER_WITH_OPTIONAL_MAX_TWO_DECIMALS)
      .withMessage(this.i18n.tr('validation.numberMaxTwoDecimals'))
      .on(this.site);
  }

  attached() {
    this.aquacomOptions = [
      { Id: 7, Name: 'Nets' },
      { Id: 12, Name: 'Fishery' },
    ];

    if (this.customerIndustryId === Models.Industries.Fishery) {
      this.aquacomModuleId = 12;
    } else if (this.customerIndustryId === Models.Industries.Aqua) {
      this.aquacomModuleId = 7;
    }
  }

  async createSite() {
    const validation = await this.validationController.validate();
    if (!validation.valid) return;

    this.site.CustomerId = this.customerId;
    this.site.DepartmentId = this.departmentId;
    this.site.AquacomModuleId = this.aquacomModuleId;

    this.siteService
      .checkExistingSite(this.site.CustomerId, this.site.Name)
      .then((result) => {
        if (!result.HasLikelyMatch) {
          this.doCreateSite();
        } else {
          this.dialogService
            .open({
              viewModel: Prompt,
              model: {
                header: 'site.possibleDuplicate',
                message:
                  this.i18n.tr('site.confirmCreateSimilar') +
                  result.SiteName +
                  (result.DepartmentName ? ` (${result.DepartmentName})` : ''),
                messagePreparsed: true,
                actions: {
                  continue: { enabled: true, t: 'site.createNewAnyway' },
                  cancel: { enabled: true, t: 'dialog.cancel' },
                },
              },
            })
            .whenClosed((res) => {
              if (!res.wasCancelled) {
                this.doCreateSite();
              }
            });
        }
      })
      .catch((err) => this.errorService.handleError(err));
  }

  public doCreateSite() {
    this.siteService
      .post(this.site)
      .then((res) => {
        this.eventAggregator.publish('siteListReset', 1);
        this.eventAggregator.publish('siteFormNewClose', 1);
        this.toastService.showSuccess('site.created');
        this.site = new Models.Site();
      })
      .catch((err) => this.errorService.handleError(err));
  }

  cancel() {
    this.eventAggregator.publish('siteFormNewClose', 1);
  }
}
